import React from 'react';

import PageHeader from 'components/shared/PageHeader';
import SEO from 'components/shared/SEO';

const PageNotFound = () => {
   const headerText = [
      'Nie udało nam się znaleźć strony, której szukasz.',
      <strong>Przepraszamy za utrudnienia.</strong>,
   ];

   return (
      <>
         <SEO title="Nie znaleziono" />
         <PageHeader headerText={headerText} />
      </>
   );
};

export default PageNotFound;
